import React from 'react'
import * as Icon from 'react-feather'
import { FacebookShareButton, TwitterShareButton, EmailShareButton } from 'react-share'

const Share = ({ url, title, emailBody }) => {

    return (
        <div className="share">
            <div className="share_layout">
                <FacebookShareButton
                    url={url}
                    quote={title}
                    className="share_btn facebook"
                    resetButtonStyle={false}
                >
                    <Icon.Facebook className="share_btn_icn" />
                </FacebookShareButton>
                <TwitterShareButton
                    url={url}
                    title={title}
                    className="share_btn twitter"
                    resetButtonStyle={false}
                >
                    <Icon.Twitter className="share_btn_icn" />
                </TwitterShareButton>
                <EmailShareButton
                  url={url}
                  subject={title}
                  body={emailBody}
                  className="share_btn email"
                  resetButtonStyle={false}
                >
                  <Icon.Mail className="share_btn_icn" />
                </EmailShareButton>
            </div>
        </div>
    )
}

export default Share