import React, { useState } from "react";
import { Helmet } from 'react-helmet'
import { Container } from 'react-bootstrap';
import Lottie from 'react-lottie';
import Layout from '../../components/layout';
import ReactPlayer from 'react-player'
import Share from '../../components/share'

import brianCircleImg from '../../images/write-to-raise/brian-blessed-circle.png';
import write2raiseCircles from '../../images/write-to-raise/write2raise_circles.png';
import hawkmenLoop from '../../images/write-to-raise/hawkmen-loop.gif';
import write2raiseLogo from '../../images/write-to-raise/write2raise-logo.svg';
import wtrChecklistImg from "../../images/write-to-raise/checklist.svg";

import strangImg from '../../images/write-to-raise/strang.png';
import terryRockyImg from '../../images/write-to-raise/terry-rocky.png';

import bookContentsImg from '../../images/write-to-raise/book-contents.png';
import bulletImg from '../../images/write-to-raise/bullets.svg'
import animationData from '../../images/write-to-raise/animationHero.json';
import Seo from "../../components/seo";
import * as styles from './WriteToRaise.module.scss';

const WriteToRaise = (props) => {

  const [amount, setAmount] = useState(null);
  const [volume, setVolume] = useState(0);

  const getAmount = value => {
    const amount = (value * 4.99 * 2.5).toFixed(2);
    setAmount(amount);
  }

  const animationOptions = {
    loop: false,
    autoplay: true,
    animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid meet'
    }
  }


  return (
    <>
      <Layout location={props.location}>
        <Seo
          title="Write To Raise with Brian Blessed"
          description="Turning words into money for your classroom just when you need it the most"
          image="/share/write-to-raise.png"
          slug={props.location.pathname}
        />
        <Helmet>
          <title>BoomWriter UK: Write To Raise</title>
          <meta name="description" content="Our book packages are affordable and ensure every pupil receives a copy of the printed book." />
        </Helmet>
        <div className={styles.hero}>
          <Container>
            <div className={styles.heroContent}>
              <div className={styles.heroInfo}>
                <img className={styles.heroCircles} src={write2raiseCircles} alt='BoomWriter and Brian Blessed' />
                <img className={styles.heroLogo} src={write2raiseLogo} alt='Write To Raise' />
                <h1 className={`heading ${styles.heroSubTitle}`}>Turning words into money for your classroom just when you need it the most</h1>
                <Share url={`${process.env.SITE_URL}${props.location.pathname}`} title="Write to Raise with Brian Blessed" emailBody="Here's a great way to raise funds for your school and promote writing at the same time: " />
              </div>
              <div className={styles.heroAnimation}>
                <Lottie
                  speed={1}
                  options={animationOptions}
                />
                {/* <h2 className={styles.heroAnimation_ext}>Now Closed</h2> */}
              </div>
            </div>
          </Container>
        </div>
        <div className={styles.focus}>
          <Container>
            <div className={styles.focusInfo}>
              <h2 className="headingSmall fontWeight-reg">A huge thank you to all the children for writing such amazing stories</h2>
            </div>
          </Container>
        </div>
        {/* <Container>
          <div className={styles.boomwriter}>
            <h2 className={`${styles.displayTitle}`}>BoomWriter, Blessed and YOU!</h2>
            <div className={styles.boomwriterContent}>
              <div className={styles.boomwriterVidWrapper}>
                {volume === 0 && (
                  <div className={styles.boomwriterVidControl} onClick={() => setVolume(1)}></div>
                )}
                <div className={`${"inlinePlayerLayout"} ${styles.boomwriterVideo}`}>
                  <ReactPlayer
                    className="inlinePlayer"
                    width="100%"
                    height="100%"
                    url="https://player.vimeo.com/video/771225492?h=39e2a0ef4d"
                    controls={true}
                    playing={true}
                    volume={volume}
                    config={{
                      vimeo: {
                        playerOptions: {
                          title: false,
                          byline: false,
                        }
                      }
                    }} />
                </div>
              </div>
              <div className={styles.boomwriterInfo}>
                <p>Your classrooms work with Brian to finish and publish a short book that is available for purchase. </p>
                <p className={styles.boomwriterProfits}>Your school keeps all the profit to help raise funds for your classrooms.</p>
                <p>Join the hundreds of schools already signed up. Register <strong>TODAY!</strong></p>
              </div>
            </div>
          </div>
        </Container> */}

        <Container className={styles.challenge}>
          <div className={styles.challengeIntro}>
            <h2 className={`${styles.displayTitle} txt-center mb-0`}>The Challenge</h2>
            <p className="headingSmall mb-0">Schools all over the UK worked with Brian Blessed to create their own '<em>Adventures of Brian Benedick</em>'.</p>
            <div>
              <div className={`inlinePlayerLayout`}>
                <ReactPlayer
                  className="inlinePlayer"
                  width="100%"
                  height="100%"
                  url="https://player.vimeo.com/video/789672342?h=f6c31d2ee0"
                  controls={true}
                  playing={false}
                  config={{
                    vimeo: {
                      playerOptions: {
                        title: false,
                        byline: false,
                      }
                    }
                  }} />
              </div>
            </div>
            {/* <a href={process.env.WRITE_TO_RAISE_REGISTER_URL} className="primary-btn btn-full">Register Now</a> */}
          </div>

          <div className={`${styles.challengeBook}`}>
            <div className={styles.challengeBookImgMobile}>
              <img className={styles.challengeBookImgMobileLeft} src={strangImg} alt="Book cover: The Adventures of Brian Benedick" />
              <img className={styles.challengeBookImgMobileRight} src={terryRockyImg} alt="Book cover: The Adventures of Brian Benedick" />
            </div>
            <div className={styles.challengeBookImgDesktop}><img className={styles.challengeBookImgDesktopLeft} src={strangImg} alt="Book cover: The Adventures of Brian Benedick" /></div>
            <div>
              <p>Our hero Brian, his best friend the giant ‘Strang the Terrible’ and Rocky the dog ride off on the back of Terry the Triceratops to explore a cavern which is a portal to unknown, mysterious landscapes.</p>
            </div>
            <div className={styles.challengeBookImgDesktop}><img className={styles.challengeBookImgDesktopRight} src={terryRockyImg} alt="Book cover: The Adventures of Brian Benedick"  /></div>

          </div>

          <div className={styles.challengeContents}>
            <h3 className={`${styles.displayTitle} txt-center mb-0`}>Every Child Published</h3>
            <div className={styles.challengeContents_layout}>
              <div className={styles.challengeContents_col1}>
                <img src={bookContentsImg} alt="" className={styles.challengeContents_img} />
                <p className="mb-2">Thanks to Write to Raise, hundreds of children accross teh country are now published writers.</p>
              </div>
              <div>
                <p className="headingSmall">Each personalised book contains…</p>
                <ul className={styles.bullets}>
                  <li><img src={bulletImg} alt='' /> Name on cover</li>
                  <li><img src={bulletImg} alt='' /> Class winning entry</li>
                  <li><img src={bulletImg} alt='' /> 2 runner up entries</li>
                  <li><img src={bulletImg} alt='' /> Pupil's own entry</li>
                </ul>
              </div>
            </div>
          </div>
        </Container>

        {/* <div className={styles.raise}>
          <Container>
            <div className={styles.raiseContent}>
              <div>
                <h3>How much can you raise!</h3>
                <p className="mb-2">Books are affordable and can be purchased by family and friends from the BoomWriter store for just £7.99.</p>
                <p><strong>Your school receives £4.99 for every copy sold.</strong></p>
              </div>
              <div className={styles.raiseAmount}>
                <p className="fontWeight-bold">How many pupils in your school would take part?</p>
                <div className={styles.raiseForm}>
                  <div className={styles.raiseFormInputArea}>
                    <input
                      type="number"
                      min={0}
                      placeholder="Number of pupils"
                      onChange={(e) => getAmount(e.target.value)}
                    />
                    <p className={styles.raiseNote}>(Recommended for ages 6-11)</p>
                  </div>
                  <div className={styles.raiseFormValue}>£{amount || '??'}</div>
                </div>
                <p className={styles.raiseSmallNote}>Amount shown is an estimate based on our average sales rate of 2.5 book sales per pupil. Actual amount raised can vary.</p>
              </div>
            </div>
          </Container>
        </div> */}

        {/* <Container>
          <div className={styles.steps}>
            <h2 className={`${styles.displayTitle} txt-center mb-0`}>It's Easy!</h2>
            <p className="txt-center mb-2">Complete your books before 1st June 2023.</p>
            <div className={styles.stepsContent}>
              <div className={styles.stepsItem}>
                <div className={styles.stepsItemTitle}>
                  <span className={`${styles.stepsItemNumber}`}>1</span>
                  <h4 className={styles.stepsItemTitle_txt}>Plan Your Books</h4>
                </div>
                <p>You'll be able to download our easy-to-follow guides to learn how to get the best out of this amazing writing event.</p>
              </div>
              <div className={`${styles.stepsItem} ${styles.stepsItemTwo}`}>
                <div className={`${styles.stepsItemTitle} ${styles.stepsItemTitleRight}`}>
                  <span className={`${styles.stepsItemNumber}`}>2</span>
                  <h4 className={styles.stepsItemTitle_txt}>Write & Publish</h4>
                </div>
                <p>We'll print and ship the books to your school for you to hold a publishing assembly.</p>
              </div>
              <div className={styles.stepsVideo}>
                <div className="inlinePlayerLayout">
                  <ReactPlayer
                    className="inlinePlayer"
                    width="100%"
                    height="100%"
                    url="https://vimeo.com/771510780/c4be115fd2"
                    controls={true}
                    config={{
                      vimeo: { playerOptions: { title: false, byline: false, } }
                    }} />
                </div>
              </div>
            </div>
            <div className={styles.stepsLast}>
              <div className={styles.stepsLastHeader}>
                <div className={styles.stepsLastHeader_title}>
                  <span className={`${styles.stepsItemNumber}`}>3</span>
                  <h4 className="headingSmall">A surprise visit by Brian</h4>
                </div>
                <img src={brianCircleImg} alt='Brian Blessed' />
              </div>
              <p>One lucky school will receive a surprise visit from Brian to finish the school year with a BOOM!</p>
            </div>
            <a href={process.env.WRITE_TO_RAISE_REGISTER_URL} className={`${styles.stepsRegisterBtn} ${'primary-btn'}`}>Start Your Books</a>
          </div>
        </Container> */}

        {/* <div className={styles.support}>
          <Container className={styles.supportMain}>
            <h3 className="headinghero">How do we support your teachers?</h3>
            <ul className={styles.supportChecklist}>
              <li className="headingSmall"><img src={wtrChecklistImg} alt="" className={styles.supportChecklist_img} /> Lesson planning tips</li>
              <li className="headingSmall"><img src={wtrChecklistImg} alt="" className={styles.supportChecklist_img} /> Supporting materials</li>
              <li className="headingSmall"><img src={wtrChecklistImg} alt="" className={styles.supportChecklist_img} /> Videos from Brian to motivate your pupils</li>
            </ul>
          </Container>
        </div> */}

        <div className={styles.king}>
          <Container className={styles.kingWrapper}>
            <div className={styles.kingContent}>
              <img src={hawkmenLoop} alt='The King of Boom' />
              <div className={styles.kingInfo}>
                <h3>The King of Boom!</h3>
                <p><b>Brian Blessed OBE is truely our loudest cheerleader.</b></p>
                <p>You will recognise him from Hollywood movies such as Flash Gordon and the Star Wars saga. He is a writer and explorer who has scaled Mount Everest and reached the North Pole. Brian is also passionate about education and philanthropy and in 2016 was made Officer of the Order of the British Empire.</p>
              </div>
            </div>
          </Container>
        </div>
      </Layout>

    </>
  )
}

export default WriteToRaise